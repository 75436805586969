import styled from "styled-components";
import { propsToPx } from "../Layout/Layout";

/**
 * These styles are used for the links
 * displayed on each category page and
 * in the favorites widget.
 */
export const ListOfLinks = styled.ul`
  list-style-type: none;
`;

type ListItemProps = {
  padding?: number;
};

export const ListItem = styled.li<ListItemProps>`
  padding: ${props => (props.padding ? propsToPx(props.padding) : "10px 0")};
`;
