import React from "react";
import { useUserData } from "contexts/UserDataContext";
import { Role } from "components/app/users/types";

interface RoleBasedComponentProps {
  children: React.ReactNode /** React children of the component */;
  exclude?: Role[] /** list of user roles, any of which will exclude */;
  require?: Role[] /** list of user roles, any of which will allow */;
  fallbackComponent?: React.ReactNode /** component to render in place of child if permissions not met */;
}

/**
 * Returns the children component if the user's roles contains at least one role
 * listed in 'require' and does not contain any roles listed in 'exclude'. If the
 * check fails and a fallback component is specified, the fallback component is
 * rendered instead.
 * @param param0
 */
export const RoleBasedComponent: React.FC<RoleBasedComponentProps> = ({
  children,
  exclude,
  require,
  fallbackComponent = null,
}) => {
  const { userData } = useUserData();
  if (
    (!exclude || exclude.length === 0) &&
    (!require || require.length === 0)
  ) {
    throw new Error(
      "At least one value for 'exclude' or 'require' is required",
    );
  }

  const roleMatch = (a: Role[], b: Role[]) => a.some(r => b.includes(r));

  const validRequire =
    require && require.length > 0 ? roleMatch(require, userData.roles) : true;

  const validExclude =
    exclude && exclude.length > 0 ? !roleMatch(exclude, userData.roles) : true;

  if (validRequire && validExclude) {
    return <>{children}</>;
  }

  return <>{fallbackComponent}</>;
};

export default RoleBasedComponent;
