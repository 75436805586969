import styled from "styled-components/macro";
import { propsToPx } from "./Layout";

/**
 * Its a layout that is columns, but then when it gets
 * narrow, it stops being columns! The first thing in the layout,
 * which would be on the right moves to the top. To use this,
 * put two DIVs inside, and on big screens, it will look like:
 * _________________
 * |          |     |
 * |   2nd    | 1st |
 * |          |     |
 * ------------------
 *
 * Then when it gets small, it looks like:
 * _________
 * |  1st  |
 * ---------
 * |  2nd  |
 * |       |
 * ---------
 *
 * Pretty sweet, eh?
 *
 * By default it has a 3:2 width ratio; meaning that the right column
 * is 66% the width of the left column. Since that is the only use case
 * right now, that's how it is! It enforces a margin between the left / right
 * split and the vertical split. You can maybe add props for that if you
 * need to change it someday.
 *
 * Should you be the person modifying this to let you tweak the ratio, have it
 * take them as a single prop like `ratio: {left: 3: right: 1}` or something.
 */
export const RespsonsiveColumnLayout = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex: 1;
  & > * {
    flex: 3 1 0;
    &:first-child {
      flex: 2 1 0;
      margin-left: ${() => propsToPx(4)};
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    & > * {
      flex: 1;
      &:first-child {
        flex: 1;
        margin-left: 0;
        margin-bottom: ${() => propsToPx(2)};
      }
    }
  }
`;

export default RespsonsiveColumnLayout;
