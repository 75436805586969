import React, { ReactNode } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { User } from "@auth0/auth0-spa-js";

interface AuthContextProps {
  token: string;
  user: User;
  setUser: (userData: User) => void;
}

interface AuthProviderProps {
  children: ReactNode;
  defaultUser?: User;
}

const AuthContext = React.createContext<AuthContextProps>(
  {} as AuthContextProps,
);

const AuthProvider = (props: AuthProviderProps) => {
  const { children, defaultUser = {} as User } = props;
  const [user, setUser] = React.useState<User>(defaultUser);
  const [token, setToken] = React.useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();
  // console.log("AuthProvider got a user of:", user);
  React.useEffect(() => {
    getAccessTokenSilently().then(token => {
      setToken(token);
    });
  }, [getAccessTokenSilently]);
  if (token === null) {
    return <h1>Authenticating...</h1>;
  }
  return (
    <AuthContext.Provider value={{ user, setUser, token }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined || context.setUser === undefined) {
    throw new Error(
      "useAuthContext must be used in a valid AuthContextProvider",
    );
  }
  return context;
};

export { AuthProvider, useAuthContext };
