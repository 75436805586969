import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  HttpLink,
  from,
} from "@apollo/client";
import { configuration } from "Configuration";
import React from "react";
import { useAuthContext } from "contexts/AuthContext";

export const GraphQLContext = (props: { children: React.ReactNode }) => {
  const { token } = useAuthContext();
  // console.log("Auth context returned token %s, to GraphQL Context",token)

  const apolloLink = new ApolloLink((operation, forward) => {
    const { api } = operation.getContext();
    switch (api) {
      // add more endpoints by adding cases in the future
      default:
        operation.setContext({
          uri: configuration.BUILDING_MODEL_API_URL,
          headers: {
            "x-api-key": configuration.BUILDING_MODEL_API_KEY,
            Authorization: `Bearer ${token}`,
          },
        });
        break;
    }
    return forward ? forward(operation) : null;
  });

  const client = new ApolloClient({
    uri: configuration.BUILDING_MODEL_API_URL,
    link: from([apolloLink, new HttpLink()]),
    cache: new InMemoryCache({ addTypename: false }),
  });
  return !token ? (
    <h1>Waiting for Auth Token...</h1>
  ) : (
    <ApolloProvider client={client}>{props.children}</ApolloProvider>
  );
};
