import { configuration } from "Configuration";
import { datadogLogs } from "@datadog/browser-logs";

export const datadogLogging = {
  init: () => {
    if (configuration.DD_TOKEN) {
      console.log("initializing datadog!");
      datadogLogs.init({
        clientToken: configuration.DD_TOKEN,
        forwardErrorsToLogs: true,
        env: configuration.ENVIRONMENT,
        service: "supportal-ui",
        version: configuration.VERSION,
      });
    }
    /*
    should we ever add RUM stuff here, you need to install 
    @datadog/browser-rum
    and then do this:

    if (configuration.DD_RUM_TOKEN && configuration.DD_RUM_APP_ID) {
      datadogRum.init({
        applicationId: configuration.DD_RUM_APP_ID,
        clientToken: configuration.DD_RUM_TOKEN,
        sampleRate: 100,
        trackInteractions: true,
        env: configuration.ENVIRONMENT,
        service: `hatch-ui`,
        version: configuration.VERSION,
      });
    }

    you will also need to add RUM stuff to he context below
    */
  },
  setDDUserContext: ({ userId }: { userId: string }) => {
    console.log("Setting datadog global context!!!");
    datadogLogs.setLoggerGlobalContext({
      id: userId,
      service: "supportal-ui",
    });
  },
  /*
  logEvent: (eventName: string, eventProperties?: Record<string, any>) => {
    
  },
  logError: (
    eventName: string,
    eventProperties: Record<string, any> = {},
    error: unknown = {},
  ) => {

  },
  */
};
