export const AppColors = {
  primary: {
    primaryGreen: "#0b8845",
    secondaryGreen: "#2eae54",
    tertiaryGreen: "#46c85e",
    "msr-green": "#2DB892",
    "light-msr-green": "#32CCA2",
    "light-msr-green-1": "#46D2AC",
    backgroundGreen: "#e1e9e1",
  },
  semantic: {
    orange: {
      orange: "#f5a623",
      orange45: "#fad291",
      orange30: "#fef4d1",
      orange15: "#fef2de",
    },
    lightBlue: {
      lightBlue: "#56adff",
      lightBlue45: "#AAD6ff",
      lightBlue15: "#e6f3ff",
      seafoam: "#91D8D5",
      "light-seafoam-1": "#B7E5E3",
      "light-seafoam-3": "#DCF3F2",
      "light-seafoam-4": "#EFF9F9",
    },
    red: {
      red45: "#f6a2a2",
      red15: "#fde3e3",
      red: "#D33336",
      "light-red-1": "#D6565D",
      "light-red-3": "#E59398",
      "light-red-7": "#F9E5E6",
    },
    green: {
      green: "#2eae54",
      green45: "#96d6a9",
      green15: "#e0f3e6",
      "dark-msr-green-1": "#238F71",
      "light-msr-green": "#32CCA2",
      "light-msr-green-3": "#6FDCBE",
      "light-msr-green-6": "#ADEBDA",
      "light-msr-green-8": "#D6F5EC",
      "light-msr-green-9": "#EAF8F4",
    },
    blue: {
      blue: "#2357d3",
      blue45: "#91abe9",
      blue15: "#dee6f9",
      sky: "#2D8EB8",
      "light-sky-1": "#46A8D2",
      "light-sky-3": "#6FBBDC",
      "light-sky-8": "#D6EBF5",
    },
    purple: {
      purple: "#7739ae",
      purple45: "#bb9cd6",
      purple15: "#ebe2f3",
    },
    lightPurple: {
      lightPurple: "#b37ddf",
      lightPurple45: "#d9beef",
      lightPurple15: "#f4ecfa",
      "light-purple": "#9B9EDA",
      "light-purple-1": "#DEDEE9",
    },
    lightGreen: {
      lightGreen: "#98d950",
      lightGreen45: "#cbeca7",
      lightGreen15: "#f0fae5",
      "lime-green": "#C0C011",
      "light-lime-green-1": "#EBEB19",
      "light-lime-green-3": "#EFEF48",
      "light-lime-green-8": "#F9F9BD",
    },
    yellow: {
      yellow: "#FFC84D",
      "light-yellow-1": "#FFD880",
      "light-yellow-2": "#FFE9B7",
      "light-yellow-3": "#FFE8B3",
      "light-yellow-4": "#FFEFCD",
    },
  },
  neutral: {
    darkestGrey: "#2b3034",
    darkGrey: "#444c54",
    mediumGrey: "#818e99",
    lightGrey: "#dfe5eb",
    lightestGrey: "#f4f4f5",
    navy: "#303D51",
    "light-navy-1": "#435571",
    "light-navy-3": "#516789",
    "light-navy-9": "#B9C2CF",
    "light-navy-11": "#E8ECF2",
    "light-gray-9": "#F6F6F6",
    white: "#FFFFFF",
    black: "#303D51",
  },
} as const;

/*
primary: {
         },
         semantic: {
           red: {
           },
           green: {
           },
           blue: {
           },
           purple: {
             purple: "#53568E",
             purple45: "#ED72D4",
           },
           lightPurple: {
           },
           lightGreen: {
           },
         },
         neutral: {
         },
         explorer: {
           green: "#2DB892",
           lightBlue: "#56adff",
           purple: "#7739ae",
           orange: "#f5a623",
           lime: "#98d950",
           blue: "#2357d3",
           lightPurple: "#b37ddf",
           pink: "#ed72d4",
           rust: "#f86207",
           yellow: "#f5e300",
         },
         */
