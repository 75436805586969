import styled from "styled-components/macro";
import { Heading2, Body2, InfoText2 } from "components/common/Typography";
import { useAuthContext } from "contexts/AuthContext";
import { useUserData } from "contexts/UserDataContext";
import {
  BookendLayout,
  HorizontalLayout,
  Box,
  VerticalLayout,
} from "components/common/Layout";
import hatchBot from "./HatchBot.svg";
import { Link } from "react-router-dom";
import { AppColors } from "components/common/Styling";
import { useAuth0 } from "@auth0/auth0-react";
import DropdownMenu from "components/common/DropdownMenu";
import { MenuItem } from "@szhsin/react-menu";
import { MobileNavigation } from "../Navigation/MobileNavigation";

export const AppHeader = () => {
  const { user } = useAuthContext();
  const { userData } = useUserData();
  const { logout } = useAuth0();
  return (
    <AppHeaderBar>
      <Box padding={[2, 4]}>
        <BookendLayout>
          <HorizontalLayout childSpacing={2}>
            <Link to="/">
              <img
                height={32}
                src={hatchBot}
                alt="Hatch Bot"
                title="HatchBot is happy to help!"
              />
            </Link>
            <HeaderLink to="/">
              <Heading2>Supportal</Heading2>
            </HeaderLink>
          </HorizontalLayout>
          <HorizontalLayout childSpacing={2}>
            <VerticalLayout childSpacing={0}>
              <Body2>
                {userData.firstName} {userData.lastName}
              </Body2>
              <InfoText2>{userData.email}</InfoText2>
            </VerticalLayout>
            <DropdownMenu
              menuButton={
                <HeightConstrainedImg src={user.picture} alt={user.name} />
              }
            >
              <MobileNavigation />
              <MenuItem onClick={e => logout()}>Log Out</MenuItem>
            </DropdownMenu>
          </HorizontalLayout>
        </BookendLayout>
      </Box>
    </AppHeaderBar>
  );
};
const AppHeaderBar = styled.div`
  background-color: ${AppColors.neutral.lightGrey};
  border-bottom: 1px solid ${AppColors.neutral.mediumGrey};
  color: ${AppColors.neutral.darkGrey};
`;
const HeightConstrainedImg = styled.img`
  height: 48px;
  width: auto;
`;

const HeaderLink = styled(Link)`
  text-decoration: none;
  color: ${AppColors.neutral.darkGrey};
`;
