import mixpanel from "mixpanel-browser";
import { configuration } from "Configuration";
console.log(configuration.MIXPANEL_TOKEN);
mixpanel.init(configuration.MIXPANEL_TOKEN);

export const mixpanelTracking = {
  trackEvent: (eventName: string, eventProperties?: Record<string, any>) => {
    mixpanel.track(`SUPPORTAL_${eventName}`, eventProperties);
  },
  trackLogin: (userId: string) => {
    mixpanel.identify(userId);
    mixpanel.track("SUPPORTAL_LOGIN");
  },
  trackError: (
    eventName: string,
    eventProperties: Record<string, any> = {},
    error: unknown = {},
  ) => {
    eventProperties.error = error;
    mixpanel.track(`SUPPORTAL_ERROR_${eventName}`, eventProperties);
  },
};
