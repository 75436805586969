import styled from "styled-components/macro";
export const FancyList = styled.ul`
  & li {
    margin-left: 1em;
    margin-bottom: 4px;
  }
  & li::marker {
    content: "✵ ";
  }
`;
