declare global {
  interface Window {
    __reactAppEnv: Configuration;
  }
}

export interface Configuration {
  DD_TOKEN?: string;
  DD_RUM_APP_ID?: string;
  DD_RUM_TOKEN?: string;
  ENVIRONMENT: string;
  VERSION: string;
  BUILDING_MODEL_API_URL?: string;
  BUILDING_MODEL_API_KEY?: string;
  AUTH0_CLAIMS_NAMESPACE: string;
  AUTH0_SETTINGS: {
    domain: string;
    clientID: string;
    redirectUri: string;
    responseType: string;
    scope?: string;
    audience?: string;
  };
  MIXPANEL_TOKEN: string;
  AUTH0_REALM: string;
  SUPPORT_EMAIL?: string;
  COMPANY_NAME?: string;
  RECAPTCHA_KEY?: string;
  SIGNUP_URL?: string;
  SIGNUP_API_KEY?: string;
}

const getEnv = (name: string): string => {
  const value = process.env[name];
  if (!value) {
    throw new Error(`Missing required configuration ${name}`);
  }
  return value;
};

// TODO: Move this so it can be read from .env
// NOTE! the DD params are intentionally missing from here so we
// do not send stuff from our local environments.
const getDevelopmentEnvironment = () =>
  ({
    AUTH0_REALM: getEnv("REACT_APP_AUTH0_REALM"),
    ENVIRONMENT: getEnv("NODE_ENV"),
    AUTH0_CLAIMS_NAMESPACE: getEnv("REACT_APP_AUTH0_CLAIMS_NAMESPACE"),
    AUTH0_SETTINGS: {
      domain: getEnv("REACT_APP_AUTH0_SETTINGS_DOMAIN"),
      clientID: getEnv("REACT_APP_AUTH0_SETTINGS_CLIENT_ID"),
      redirectUri: getEnv("REACT_APP_AUTH0_SETTINGS_REDIRECT_URI"),
      responseType: getEnv("REACT_APP_AUTH0_SETTINGS_RESPONSE_TYPE"),
      scope: getEnv("REACT_APP_AUTH0_SETTINGS_SCOPE"),
      audience: getEnv("REACT_APP_AUTH0_SETTINGS_AUDIENCE"),
    },
    BUILDING_MODEL_API_URL: getEnv("REACT_APP_BUILDING_MODEL_API_URL"),
    BUILDING_MODEL_API_KEY: getEnv("REACT_APP_BUILDING_MODEL_API_KEY"),
    MIXPANEL_TOKEN: getEnv("REACT_APP_MIXPANEL_TOKEN"),
  }) as Configuration;

export const configuration: Configuration =
  (window.__reactAppEnv) || getDevelopmentEnvironment();
