export const SUPPORTAL_FAVORITES = "supportal:favorites";

export enum FavoritesReducerActionTypes {
  ADD = "add",
  REMOVE = "remove",
}

export interface Favorite {
  category: string;
  route: string;
  text: string;
  target?: string;
}

export interface FavoritesReducerState {
  favorites: Favorite[];
}

export interface FavoritesReducerAction {
  type: FavoritesReducerActionTypes;
  payload: any;
}
