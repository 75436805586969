import useLocalStorage from "components/common/LocalStorageHook/LocalStorageHook";
import {
  Favorite,
  FavoritesReducerActionTypes,
  SUPPORTAL_FAVORITES,
} from "./types";
import { createContext, useContext, useEffect, useReducer } from "react";

export const FavoritesContext = createContext<Favorite[]>([]);

export const FavoritesProvider = (props: any) => {
  const [favorites, setFavorites] = useLocalStorage(SUPPORTAL_FAVORITES, []);
  const [state, dispatch] = useReducer(
    FavoritesReducer,
    [],
    () => favorites ?? [],
  );
  const value = [state, dispatch];

  useEffect(() => {
    setFavorites(state);
  }, [state, setFavorites]);

  return <FavoritesContext.Provider value={value} {...props} />;
};

const FavoritesReducer = (
  state: Favorite[],
  action: { type: FavoritesReducerActionTypes; payload: Favorite },
) => {
  switch (action.type) {
    case FavoritesReducerActionTypes.ADD:
      return [...state, action.payload];
    case FavoritesReducerActionTypes.REMOVE:
      return state.filter(favorite => favorite.route !== action.payload.route);
    default:
      return state;
  }
};

export const useFavorites = (): any => {
  const favoritesContext = useContext(FavoritesContext);
  if (!favoritesContext) {
    throw new Error("useFavorites must be used within a FavoritesProvider");
  }

  return favoritesContext;
};
